<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Total Nilai PO</label>
        <v-money class="w-full" v-model="data.nilai_total" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">DPP</label>
        <v-money class="w-full" v-model="data.dpp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN Jenis</label>
        <vs-input class="w-full" :value="data.ppn_jenis || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN</label>
        <vs-input class="w-full" :value="`${idr(data.ppn_jml || 0)} (${data.ppn_persen || 0}%)`" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="data.pph_nama || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="`${idr(data.pph_jml || 0)} (${data.pph_persen || 0}%)`" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Status Rekanan</label>
        <vs-input class="w-full" :value="data.nppkp_rekanan ? 'PKP' : 'NON PKP'" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3 mt-base">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="listTermin" class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Termin</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl Due</vs-th>
            <vs-th class="whitespace-no-wrap">Persen Bayar</vs-th>
            <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
            <vs-th class="whitespace-no-wrap">DPP</vs-th>
            <vs-th class="whitespace-no-wrap">PPN</vs-th>
            <vs-th class="whitespace-no-wrap">PPh</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm" :class="{'bg-warning text-black font-semibold': isItemTerminHighlighted(item) }">
              <vs-td class="whitespace-no-wrap">Termin {{ item.no_termin }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr }}%</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_total | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.ppn_jml | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.keterangan || '-' }}</vs-td>
            </vs-tr>
            <vs-tr class="text-sm font-semibold">
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td class="text-right">{{ footer.totalPersen | idr }}%</vs-td>
              <vs-td class="text-right">{{ footer.totalJumlah | idr }}</vs-td>
              <vs-td class="text-right">{{ footer.totalDpp | idr }}</vs-td>
              <vs-td class="text-right"> {{ footer.totalPpn | idr }}</vs-td>
              <vs-td class="text-right"> {{ footer.totalPph | idr }}</vs-td>
              <vs-td></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'
import PoRepository from '@/repositories/proyek/po-repository'
import _ from 'lodash'

export default {
  name: 'TabTax',
  props: ['isActive', 'idPo', 'idPoD', 'po'],
  components: {
    VMoney
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data = _.cloneDeep(this.po)
        if (this.isFirstActive) {
          this.getInitData()
          this.isFirstActive = false
        }
      }
    }
  },
  computed: {
    footer () {
      return {
        totalPersen: _.sumBy(this.listTermin, item => item.persen || 0),
        totalJumlah: _.sumBy(this.listTermin, item => item.jml_total || 0),
        totalDpp: _.sumBy(this.listTermin, item => item.dpp || 0),
        totalPpn: _.sumBy(this.listTermin, item => item.ppn_jml || 0),
        totalPph: _.sumBy(this.listTermin, item => item.pph_jml || 0)
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      data: {},
      listTermin: []
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      PoRepository.getPoTermin(this.idPo)
        .then(response => {
          this.listTermin = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    isItemTerminHighlighted (item) {
      return item.id === this.idPoD
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.poTerminApproval.activeTab
      this.$store.commit('approvals/poTerminApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.poTerminApproval.activeTab
      this.$store.commit('approvals/poTerminApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
